import NotFoundHero from 'shared-components/ui/NotFoundHero'
import DoubleListing from 'shared-components/posts/DoubleListing'
import styleUtils from 'shared-components/styles/util.module.css'
import styles from './PageNotFoundTemplate.module.css'
import clsx from 'clsx'
import { DSButtonsList, DSPageNotFound, DSPostShortWithAuthor } from 'shared-definitions/types'

interface PageNotFoundTemplateProps {
  page: DSPageNotFound
  suggestions: DSButtonsList | null | undefined
  pageLoader: (
    page: number
  ) => Promise<{ left: DSPostShortWithAuthor[]; right: DSPostShortWithAuthor[] }[]>
}

const PageNotFoundTemplate: React.VFC<PageNotFoundTemplateProps> = ({
  page,
  pageLoader,
  suggestions,
}) => (
  <>
    <NotFoundHero
      suggestions={suggestions}
      className={styles.hero}
      title={page.title}
      subtitle={page.subtitle}
      image={page.image}
      button={page.buttonHome}
    />
    <div className={styleUtils.wrapper}>
      <h3 className={clsx(styleUtils.pageBodyTitle, styles.subtitle)}>{page.postsTitle}</h3>
      <DoubleListing
        initial={{ left: page.hotPosts.items, right: page.posts.items }}
        loader={pageLoader}
      />
    </div>
  </>
)

export default PageNotFoundTemplate
