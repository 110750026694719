import styles from './NotFoundHero.module.css'
import clsx from 'clsx'
import {
  BasicStyledComponent,
  DSButtonsList,
  DSImage,
  DSLabeledLink,
} from 'shared-definitions/types'
import AppImage from 'shared-components/ui/AppImage'
import BasicButtonLink from 'shared-components/buttons/BasicButtonLink'
import Linked from '../service/Linked'

interface NotFoundHeroProps extends BasicStyledComponent {
  title: string
  subtitle: string
  button: DSLabeledLink
  image: DSImage
  suggestions: DSButtonsList | null | undefined
}

const NotFoundHero: React.VFC<NotFoundHeroProps> = ({
  className,
  title,
  subtitle,
  button,
  image,
  suggestions,
}) => {
  const showSuggestions = suggestions && suggestions.buttons.length > 0,
    showButton = suggestions === null,
    showPlaceholder = !showSuggestions && !showButton

  return (
    <div className={clsx(styles.container, className)}>
      {image ? (
        <AppImage
          imageClassName={styles.image}
          image={image}
          layout="raw"
          sizes="calc(100vw - rem(74))"
          className={styles.imageContainer}
        />
      ) : null}
      <div className={styles.title}>{title}</div>

      {showPlaceholder ? (
        <>
          <div className={styles.subtitlePlaceholder} />
          <div className={styles.buttonPlaceholder} />
        </>
      ) : null}

      {showSuggestions ? (
        <>
          <div className={styles.subtitle}>{suggestions.title}</div>
          <ul className={styles.suggestions}>
            {suggestions.buttons.map(suggestion => (
              <Linked key={suggestion.label} {...suggestion.pLink}>
                {suggestion.label}
              </Linked>
            ))}
          </ul>
        </>
      ) : null}

      {showButton ? (
        <>
          <div className={styles.subtitle}>{subtitle}</div>
          <BasicButtonLink className={styles.button} {...button.pLink}>
            {button.label}
          </BasicButtonLink>
        </>
      ) : null}
    </div>
  )
}

export default NotFoundHero
