/* eslint-disable @typescript-eslint/require-await */
/* eslint-disable react/self-closing-comp */
import { FC, useEffect, useState } from 'react'
import { EventBus } from 'shared-code/event-bus'
import { BasicStyledComponent } from 'shared-definitions/types'
import { ViafouraAPIContext } from '../contexts/ViafouraAPIContext'
import { useAbortableEffect } from '../hooks/use-abortable-effect'

interface ViafouraCommentsCounterProps extends BasicStyledComponent {
  containerId: string
}

// comments cache
const commentsCounts: Map<string, number> = new Map()
const commentsIdsToUpdate = new Set<string>()
const eb = new EventBus<'updated'>()
let timer: ReturnType<typeof setTimeout> | null = null

const ViafouraCommentsCounter: FC<ViafouraCommentsCounterProps> = ({ className, containerId }) => {
  const [counter, setCounter] = useState(0)
  const { api } = ViafouraAPIContext.useContainer()

  useEffect(() => {
    const handler = (): void => {
      if (commentsCounts.has(containerId)) {
        setCounter(commentsCounts.get(containerId)!)
      }
    }
    eb.on('updated', handler)

    return () => {
      eb.off('updated', handler)
    }
  }, [containerId])

  useAbortableEffect(
    async signal => {
      if (commentsCounts.has(containerId)) {
        setCounter(commentsCounts.get(containerId)!)
        return
      }

      commentsIdsToUpdate.add(containerId)

      if (timer) {
        clearTimeout(timer)
      }

      timer = setTimeout(() => {
        void api.getCommentsCount(signal, Array.from(commentsIdsToUpdate)).then(comments => {
          comments.forEach(comment => {
            commentsCounts.set(comment.containerId, comment.commentCount)
          })
          commentsIdsToUpdate.clear()
          eb.emit('updated')
        })
      }, 500)
    },
    [containerId]
  )

  return <div className={className}>{counter}</div>
}

export default ViafouraCommentsCounter
